import { ProductType, ResultType  } from "@gengakuji/common";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { ColorSpan } from "../components/ColorSpan";
import { H2 } from "../components/H2";
import { HistoryItem } from "../components/HistoryItem";
import { Loading } from "../components/Loading";
import { Space } from "../components/Space";
import { getKujiResults } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
const title = "くじ結果履歴一覧";

const HistoryList = memo(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [results, setResults] = useState<
    { result: ResultType; product: ProductType }[] | null
  >(null);

  const user = useAuthUser();

  // くじ結果履歴一覧を取得
  useEffect(() => {
    const fetchKujiResults = async () => {
      setIsLoading(true);
      if (user?.uid) {
        const kujiResults = await getKujiResults(user.uid);
        setResults([...kujiResults]);
      }
      setIsLoading(false);
    };
    fetchKujiResults();
  }, [user]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        <H2 text={title} blue={5} />
        {isLoading ? (
          <>
            <Space height={64} />
            <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />
            <Space height={64} />
          </>
        ) : (
          <>
            {results?.map((value) => {
              return (
                <Link
                  key={value.result.sessionId}
                  state={{
                    result: value.result,
                    product: value.product,
                  }}
                  to={`${value.result.customReceiptNumber}`}
                >
                  <HistoryItem
                    title={value.product.productName}
                    count={value.result.count}
                    date={value.result.boughtAt}
                  />
                </Link>
              );
            })}
          </>
        )}
        <>
          <Link to="/mypage">
            <ColorSpan>マイページへ戻る</ColorSpan>
          </Link>
        </>
        <Space height={44} />
      </BaseWhiteBg>
    </>
  );
});

export default HistoryList;
