import { ProductType } from "@gengakuji/common";

import {
  convertScheduledShippingAt,
  convertshippingRequestDeadline,
} from "../../utils/index";

import { BaseTable } from "./BaseTable";

interface Props {
  productData: ProductType | null;
  firstBuy: boolean;
  user: any;
  formattedStart: string;
  formattedEnd: string;
}

export const ProductTable = ({
  productData,
  firstBuy,
  user,
  formattedStart,
  formattedEnd,
}: Props) => {
  return (
    <BaseTable>
      <tr>
        <td className="min-w-[88px] text-sm">商品数</td>
        <td>
          全
          <span className="text-red font-medium">
            {(productData && productData.count) || 0}
          </span>
          種類
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">商品内容</td>
        <td>
          S賞
          <ul>
            {productData && productData.productTypes
              ? (productData.productTypes.SType ?? []).map((val, index) => {
                return (
                  <li className="mb-2" key={`s-${index}`}>
                    <span>{val}</span>
                  </li>
                );
              })
              : ""}
          </ul>
          通常賞
          <ul>
            {productData && productData.productTypes
              ? (productData.productTypes.NormalType ?? []).map(
                (val, index) => {
                  return (
                    <li className="mb-2" key={`n-${index}`}>
                      <span>{val}</span>
                    </li>
                  );
                }
              )
              : ""}
          </ul>
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">価格（税込）</td>
        {/* 初回割引率が0%の場合は、初回割引率の表記はありません。 */}
        <td>
          {(firstBuy || !user) &&
            productData &&
            productData.initialDiscountRate > 0 ? (
            <>
              <span className="text-red font-medium">
                初回限定
                {(productData && productData.initialDiscountRate) || 0}
                %OFF!
              </span>
              <div>
                <span>1回：</span>
                <span className="mr-2 font-medium line-through">
                  {(productData && productData.price1) || 0}円
                </span>
                <span className="text-red font-medium">
                  {(productData &&
                    (productData.price1 *
                      (100 - productData.initialDiscountRate)) /
                    100) ||
                    0}
                  円
                </span>
              </div>
              <div>
                <span>10回：</span>
                <span className="mr-2 font-medium line-through">
                  {(productData && productData.price10) || 0}円
                </span>
                <span className="text-red font-medium">
                  {(productData &&
                    (productData.price10 *
                      (100 - productData.initialDiscountRate)) /
                    100) ||
                    0}
                  円
                </span>
              </div>
            </>
          ) : (
            <>
              <span>1回：{(productData && productData.price1) || 0}円</span>
              <br />
              <span>10連：{(productData && productData.price10) || 0}円</span>
              {productData && !productData?.isDuplicate && (
                <>
                  {"　"}
                  <span className="text-red font-medium">10連で被りなし！</span>
                </>
              )}
            </>
          )}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">販売期間</td>
        <td>
          {formattedStart}
          <br />〜{formattedEnd}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">配送依頼期限</td>
        <td>
          {productData && productData.shippingRequestDeadline
            ? convertshippingRequestDeadline(
              productData.shippingRequestDeadline
            )
            : ""}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">発送予定時期</td>
        <td>
          {productData && productData.scheduledShippingAt
            ? convertScheduledShippingAt(productData.scheduledShippingAt)
            : ""}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">送料・梱包費（税込）</td>
        <td>
          <span>
            配送依頼ごとに全国一律
            <br />
            {(productData && productData.postage) || 0}円※個数無制限
          </span>
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">決済方法</td>
        <td>
          {productData && productData.payment ? (
            productData.payment.length > 1 ? (
              productData.payment.map((val, index) => (
                <li key={`payment-${index}`}>
                  <span>{val}</span>
                </li>
              ))
            ) : (
              <span>{productData.payment[0]}</span>
            )
          ) : (
            ""
          )}
        </td>
      </tr>
      <tr>
        <td className="min-w-[88px] text-sm">コピーライト</td>
        <td>(C) {(productData && productData.copyright) || ""}</td>
      </tr>
    </BaseTable>
  );
};
