import { ProductType } from "@gengakuji/common";
import { GengaInfoType } from "@gengakuji/common";
import { memo } from "react";

import infoTitle from "../../assets/titile/info-title.webp";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseModal } from "./BaseModal";

interface Props {
  gengaInfo: GengaInfoType | null;
  productData: ProductType | null;
  onClose: () => void;
}

export const GengaModal = memo(({ gengaInfo, productData, onClose }: Props) => {
  const handleClickOutsideORCross = () => {
    onClose();
  };

  return (
    <BaseModal onClickOutside={handleClickOutsideORCross}>
      <Img
        imgPath={`${gengaInfo?.productId}/genga/${gengaInfo?.index}.png`}
        alt="原画画像"
      />
      <Space height={32} />
      <div className="text-left text-white">
        <div
          className="flex h-[41.76px] w-[100.8px] items-center justify-center bg-cover"
          style={{
            backgroundImage: `url(${infoTitle})`,
          }}
        >
          <p>原画情報</p>
        </div>
        <Space height={16} />
        <p>
          『{productData?.anime}』{gengaInfo?.displayName}
        </p>
        <Space height={16} />
        <p>
          制作会社：
          {productData?.company && productData?.company.map((val) => val + " ")}
        </p>
        <Space height={16} />
        <p>(C) {productData?.copyright}</p>
        <Space height={48} />
      </div>
      <Space height={32} />
    </BaseModal>
  );
});
