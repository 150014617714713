import { RequestedShipInfo } from "@gengakuji/common";
import { memo } from "react";

import { convertFormattedDate, formatZipCode } from "../../utils";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  requestedShipInfo: RequestedShipInfo;
}

export const RequestedCard = memo(({ requestedShipInfo }: Props) => {
  return (
    <BaseCard>
      <Space height={16} />
      <>
        <Img
          imgPath={`${requestedShipInfo.productData.productId}/ogp.png`}
          alt="商品画像"
        />
        <Space height={16} />
        <h3>{requestedShipInfo.productData.productName}</h3>
        <Space height={8} />
        <p>全{requestedShipInfo.shipInfo.numOfAvailableShip}点</p>
      </>
      <Space height={16} />
      <div className="text-left">
        <>
          <label>配送依頼日時</label>
          <p>{convertFormattedDate(requestedShipInfo.shipInfo.createdAt)}</p>
        </>
        <Space height={16} />
        <>
          <label>発送予定時期</label>
          <p>{requestedShipInfo.shipInfo.FinalShippingDate}</p>
        </>
        <Space height={16} />
        <>
          <label>配送管理番号</label>
          <p> {requestedShipInfo.shipInfo.customReceiptNumber}</p>
        </>
        <Space height={16} />
        <>
          <label>配送先</label>
          <>
            <p>
              {requestedShipInfo.address.sei + requestedShipInfo.address.mei}
              <br />
              {requestedShipInfo.address.firstName +
                requestedShipInfo.address.lastName}
            </p>
            <p>{requestedShipInfo.address.phone}</p>
          </>
          <Space height={8} />
          <>
            <p>〒{formatZipCode(requestedShipInfo.address.zip)}</p>
            <p>
              {requestedShipInfo.address.prefecture +
                requestedShipInfo.address.city +
                requestedShipInfo.address.address}
              <br />
              {requestedShipInfo.address.building}
            </p>
          </>
        </>
      </div>
      <Space height={16} />
    </BaseCard>
  );
});
