import { CutInfoType, ProductType } from "@gengakuji/common";
import { memo } from "react";
import { Link, useParams } from "react-router-dom";

import s_icon from "../../assets/special/s_icon.webp";
import infoTitle from "../../assets/titile/info-title.webp";
import { BaseButton } from "../Button/BaseButton";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseModal } from "./BaseModal";

interface Props {
  cutInfo: CutInfoType | null;
  productData: ProductType | null;
  onClose: () => void;
}

export const CutModal = memo(({ cutInfo, productData, onClose }: Props) => {
  const { productId } = useParams<{ productId?: string }>();

  const handleClickOutsideORCross = () => {
    onClose();
  };

  return (
    <BaseModal onClickOutside={handleClickOutsideORCross}>
      <div className="relative inline-block">
        {cutInfo?.type === "S" && (
          <>
            <img
              src={s_icon}
              alt="S賞アイコン"
              className="absolute right-0 top-0 z-[101] w-1/4"
            />
          </>
        )}
        <Img
          // oddtaxiのときはindexがないのでcutId,それ以外のときはindexを使う
          imgPath={`${cutInfo?.productId}/cover/${cutInfo?.cutId || cutInfo?.index
            }.png`}
          alt="原画画像"
        />
      </div>
      <Space height={32} />
      <div className="text-left text-white">
        <div
          className="flex h-[41.76px] w-[100.8px] items-center justify-center bg-cover"
          style={{
            backgroundImage: `url(${infoTitle})`,
          }}
        >
          <p>原画情報</p>
        </div>
        <Space height={16} />
        <p>
          『{productData?.anime}』{cutInfo?.displayName}
        </p>
        <Space height={16} />
        <p>枚数：{cutInfo?.mixedCount}枚</p>
        <Space height={16} />
        <p>
          制作会社：
          {productData?.company && productData?.company.map((val) => val + " ")}
        </p>
        <Space height={16} />
        <p>(C) {productData?.copyright}</p>
        <Space height={16} />
        {/* productIdがある場合、つまり商品ページのみで表示 */}
        {productId && (
          <div className="text-center">
            <Link to={`sample/${cutInfo?.index}`}>
              <BaseButton
                title="サンプルを見る"
                backgroundColor="#c9e900"
                textColor="#000"
              />
            </Link>
          </div>
        )}
        <Space height={48} />
      </div>
      <Space height={32} />
    </BaseModal>
  );
});
