import { ProductType, CutInfoType } from "@gengakuji/common";
import { logEvent } from "firebase/analytics";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import { BgH2 } from "../components/BgH2";
import { BaseButton } from "../components/Button/BaseButton";
import { FixedBottom } from "../components/FixedBottom";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { FirestoreDocument } from "../constants";
import { analytics } from "../firebase";
import { getCutInfos, getProduct, getUser } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { buyKuji, convertTimeStampToDate } from "../utils/index";

const title = "サンプル一覧";

const SampleCollection = memo(() => {
  const { productId } = useParams<{ productId?: string }>();
  const user = useAuthUser();
  const [loading, setLoading] = useState(true);
  const [cutInfo, setCutInfo] = useState<FirestoreDocument<CutInfoType>[]>();
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [firstBuy, setFirstBuy] = useState(false);

  //商品・カット情報取得・初回購入判定
  useEffect(() => {
    const fetchData = async () => {
      if (!productId) return;

      const productPromise = getProduct(productId);
      const cutInfoPromise = getCutInfos(productId);

      const [productData, cutInfo] = await Promise.all([
        productPromise,
        cutInfoPromise,
      ]);

      setProductData(productData);
      setCutInfo(cutInfo);

      if (user?.uid) {
        const userData = await getUser(user.uid);
        setFirstBuy(!userData?.boughtProductIds.includes(productId));
      }

      setLoading(false);
    };

    fetchData();
  }, [productId, user]);

  //くじを購入する
  const handleBuyKuji = async (drawNumber: number) => {
    await buyKuji(drawNumber, firstBuy, productData);
  };

  return (
    <>
      <Helmet>
        <title>
          {title}/{productId}
        </title>
      </Helmet>
      <Space height={24} />
      <BgH2 text={title} />
      <Space height={24} />
      <p>
        サンプルのスクリーン録画を
        <br />
        SNSにシェアすることは大歓迎！
      </p>
      <Space height={24} />
      {loading ? (
        <Loading />
      ) : (
        <div className="mx-auto grid grid-cols-2 gap-4">
          {cutInfo?.map((val, index) => (
            <div key={index}>
              <Link
                to={`${val.id}`}
                onClick={() =>
                  logEvent(
                    analytics,
                    `${val.displayName}${window.location.pathname + window.location.search
                    }`
                  )
                }
              >
                <Img
                  imgPath={`${productData?.productId}/cover/${val.index}.png`}
                  alt={`商品画像${index + 1}`}
                  className="w-full shadow-md"
                />
              </Link>
              <Space height={16} />
              <p>{val.displayName}</p>
            </div>
          ))}
        </div>
      )}
      <Space height={48} />
      <Share
        url={`https://gengakuji.com/${productData?.productId}/sample`}
        text="【登録不要】縦スクロールでパラパラ動くデジタル原画の無料サンプル公開中！"
        hashtags={
          productData?.hashtags
            ? [...productData.hashtags, "原画くじ"]
            : ["原画くじ"]
        }
      />
      <Space height={48} />
      <Link to={`/${productData?.productId}`}>
        <BaseButton
          title="商品ページへ"
          backgroundColor="#c9e900"
          textColor="#000"
        />
      </Link>
      <Space height={128} />
      <FixedBottom
        user={user}
        productData={productData}
        start={
          productData ? convertTimeStampToDate(productData.start) : new Date()
        }
        end={productData ? convertTimeStampToDate(productData.end) : new Date()}
        firstBuy={firstBuy}
        handleBuyKuji={handleBuyKuji}
      />
    </>
  );
});

export default SampleCollection;
