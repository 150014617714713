import { EmailState } from "@gengakuji/common";
import { logEvent } from "firebase/analytics";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { Border } from "../components/Border";
import { BlackButton } from "../components/Button/BlackButton";
import { ColorSpan } from "../components/ColorSpan";
import { H2 } from "../components/H2";
import { Input } from "../components/Input";
import { Loading } from "../components/Loading";
import { ResetModal } from "../components/Modal/ResetModal";
import { Radio } from "../components/Radio";
import { Select } from "../components/Select";
import { Space } from "../components/Space";
import { GENDER, Gender } from "../constants";
import { analytics } from "../firebase";
import {
  createEmail,
  getEmail,
  getUser,
  updateBirth,
  updateGender,
} from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { logout } from "../lib/logout";
import {
  convertToTimestamp,
  convertDateToDays,
  convertTimeStampToDate,
} from "../utils/index";

type GenderState =
  | {
    initialized: false;
  }
  | {
    initialized: true;
    gender: Gender | null;
  };

type BirthState =
  | {
    initialized: false;
  }
  | {
    initialized: true;
    birth: {
      year: number;
      month: number;
      day: number;
    };
  }
  | {
    initialized: true;
    birth: null;
  };

const title = "マイページ";

const MyPage = memo(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setshowModal] = useState(false);

  const [emailState, setEmailState] = useState<EmailState>({
    initialized: false,
    isRegisterd: false,
    email: "",
  });
  const [emailError, setEmailError] = useState("");
  const [gender, setGender] = useState<GenderState>({ initialized: false });
  const [genderForm, setGenderForm] = useState<Gender | null>(null);
  const [birthDate, setBirthDate] = useState<BirthState>({
    initialized: false,
  });
  const [birthForm, setBirthForm] = useState({ year: 0, month: 0, day: 0 });

  const user = useAuthUser();
  const navigate = useNavigate();

  const isFormValid = emailError === "";

  // ユーザー情報を取得
  useEffect(() => {
    const fetchUser = async () => {
      if (user === null) {
        navigate("/login");
      } else {
        const uid = user.uid;
        const userData = await getUser(uid);
        const email = await getEmail(uid);
        setEmailState({
          initialized: true,
          isRegisterd: email?.email ? true : false,
          email: email?.email || "",
        });
        if (userData) {
          setGender({ initialized: true, gender: userData.gender });
          if (userData.birthDate) {
            const date = convertTimeStampToDate(userData.birthDate);
            const { year, month, day } = convertDateToDays(date);
            setBirthDate({
              initialized: true,
              birth: {
                year,
                month,
                day,
              },
            });
          } else {
            setBirthDate({
              initialized: true,
              birth: null,
            });
          }
        }
      }
      setIsLoading(false);
    };
    fetchUser();
  }, [user, navigate]);

  // 性別を変更
  const handleChangeGender = () => {
    if (!user?.uid || genderForm === null) return;
    updateGender(user.uid, genderForm);
    setGender({
      initialized: true,
      gender: genderForm,
    });
  };

  // 生年月日を変更
  const handleChangeBirthDate = () => {
    if (!user?.uid) return;

    const timestamp = convertToTimestamp(
      birthForm.year,
      birthForm.month,
      birthForm.day
    );
    updateBirth(user.uid, timestamp);
    setBirthDate({
      initialized: true,
      birth: { ...birthForm },
    });
  };

  //メールアドレスのバリデーション
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailState({ ...emailState, email: e.target.value });

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
      setEmailError("無効なメールアドレス形式です。");
      return;
    } else {
      setEmailError("");
    }
  };

  //メールアドレスを作成
  const handleChangeEmail = async () => {
    if (!user?.uid || emailState.email.length < 0) return;
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailState.email)) {
      return;
    }
    await createEmail(emailState.email, user?.uid);
    setEmailState({
      initialized: true,
      isRegisterd: true,
      email: emailState.email,
    });
  };

  // パスワード変更モーダル表示
  const showPasswordModal = () => {
    setshowModal(true);

    logEvent(
      analytics,
      `パスワードを変更する${window.location.pathname + window.location.search}`
    );
  };

  // ログアウト
  const handleLogout = async () => {
    logout();
    navigate("/");

    logEvent(
      analytics,
      `ログアウト${window.location.pathname + window.location.search}`
    );
  };

  // 年の選択肢を生成
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    for (let year = 1900; year <= currentYear; year++) {
      if (year === 2000) {
        options.push(
          <option disabled key={year} value={0}>
            年
          </option>
        );
      }
      options.push(
        <option key={year} value={year}>
          {year}年
        </option>
      );
    }
    return options;
  };

  const yearOptions = generateYearOptions();

  // 月の選択肢を生成
  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month, 0).getDate();
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        <H2 text="マイページ" blue={3} />
        {isLoading ? (
          <>
            <Space height={64} />
            <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />
            <Space height={64} />
          </>
        ) : (
          <>
            <div className="text-left">
              <label>性別</label>
            </div>
            <Space height={8} />
            <>
              {gender.initialized ? (
                gender.gender !== null ? (
                  <div className="text-left">
                    <p>
                      {gender.gender === 0
                        ? "男"
                        : gender.gender === 1
                          ? "女"
                          : "その他"}
                    </p>
                  </div>
                ) : (
                  <>
                    <Radio
                      onChange={() => setGenderForm(GENDER.MALE)}
                      gender="男性"
                    />
                    <Radio
                      onChange={() => setGenderForm(GENDER.FEMALE)}
                      gender="女性"
                    />
                    <Radio
                      onChange={() => setGenderForm(GENDER.OTHER)}
                      gender="その他"
                    />
                    <Space height={8} />
                    <BlackButton
                      onClick={handleChangeGender}
                      disabled={genderForm === null}
                      title="性別を保存する"
                    />
                    <Space height={8} />
                    <p className="text-xs text-[#999999]">
                      ※一度設定すると変更できません。
                    </p>
                  </>
                )
              ) : null}
            </>
            <Space height={24} />
            <div className="text-left">
              <label>生年月日</label>
            </div>
            <Space height={8} />
            {birthDate.initialized ? (
              birthDate.birth === null ? (
                <>
                  <Select
                    id="year"
                    value={birthForm.year}
                    onChange={(e) =>
                      setBirthForm({
                        ...birthForm,
                        year: Number(e.target.value),
                      })
                    }
                  >
                    {yearOptions}
                  </Select>
                  <Select
                    id="month"
                    value={birthForm.month}
                    onChange={(e) =>
                      setBirthForm({
                        ...birthForm,
                        month: Number(e.target.value),
                      })
                    }
                  >
                    {Array.from({ length: 13 }, (_, i) =>
                      i === 0 ? (
                        <option disabled key={i} value={0}>
                          月
                        </option>
                      ) : (
                        <option key={i} value={i}>
                          {i}月
                        </option>
                      )
                    )}
                  </Select>
                  <Select
                    id="day"
                    value={birthForm.day}
                    onChange={(e) =>
                      setBirthForm({
                        ...birthForm,
                        day: Number(e.target.value),
                      })
                    }
                  >
                    {Array.from(
                      {
                        length:
                          getDaysInMonth(birthForm.month, birthForm.year) + 1,
                      },
                      (_, i) =>
                        i === 0 ? (
                          <option disabled key={i} value={0}>
                            日
                          </option>
                        ) : (
                          <option key={i} value={i}>
                            {i}日
                          </option>
                        )
                    )}
                  </Select>
                  <Space height={8} />
                  <BlackButton
                    onClick={handleChangeBirthDate}
                    disabled={
                      !birthForm.year || !birthForm.month || !birthForm.day
                    }
                    title="生年月日を保存する"
                  />
                  <Space height={8} />
                  <p className="text-xs text-[#999999]">
                    ※一度設定すると変更できません。
                  </p>
                </>
              ) : (
                <div className="text-left">
                  <p>
                    {birthDate.birth.year}年{birthDate.birth.month}月
                    {birthDate.birth.day}日
                  </p>
                </div>
              )
            ) : null}
            <Space height={48} />
            <Border color="#cacaca" />
            <Space height={48} />
            <>
              <div className="text-left">
                <label>メールアドレス</label>
              </div>
              <Space height={8} />
              {emailState.initialized ? (
                emailState.isRegisterd ? (
                  <div className="text-left">
                    <p>{emailState.email}</p>
                  </div>
                ) : (
                  <>
                    <Input
                      type="email"
                      name="email"
                      placeholder="メールアドレス"
                      value={emailState.email}
                      onChange={handleEmailChange}
                      errorMessage={emailError}
                    />
                    <Space height={8} />
                    <button
                      onClick={handleChangeEmail}
                      className={`h-10 w-full cursor-pointer bg-black p-2 text-sm text-white ${!isFormValid ? "disabled-button" : ""
                        }`}
                      disabled={!isFormValid}
                    >
                      メールアドレスを保存する
                    </button>
                    <Space height={8} />
                    <p className="text-xs text-[#999999]">
                      ※一度設定すると変更できません。
                    </p>
                  </>
                )
              ) : null}
            </>
            {user?.loginMethods.includes("password") && (
              <>
                <Space height={24} />
                <div className="text-left">
                  <label>パスワード</label>
                  <Space height={8} />
                  <p>**********</p>
                </div>
                <Space height={8} />
                <BlackButton
                  title="パスワードを変更する"
                  onClick={showPasswordModal}
                />
              </>
            )}
            <Space height={48} />
            <Border color="#cacaca" />
            <Space height={48} />
            <div className="text-left">
              <label>履歴</label>
              <Space height={8} />
              <Link
                to="history"
                className="flex h-8 w-[calc(100%-8px)] justify-center rounded-lg bg-white p-2 pr-0"
              >
                <div className="flex w-full items-center justify-between">
                  <p>くじ結果履歴を見る</p>
                  <span>＞</span>
                </div>
              </Link>
            </div>
            <Space height={48} />
            <div className="flex w-full justify-center">
              <ColorSpan onClick={handleLogout}>ログアウト</ColorSpan>
            </div>
            <Space height={48} />
          </>
        )}
        {showModal && <ResetModal onClose={() => setshowModal(false)} />}
      </BaseWhiteBg>
    </>
  );
});

export default MyPage;
